import moment from "moment";

const helperDate = {
  getAge(date) {
    // recibe formato 'yyyy-mm-dd'
    return moment().diff(date, "years");
  }
};

export default helperDate;
