<template>
  <v-container>
    <GoBackBtn v-if="newAfiTitular" :routeToGo="routeToGo" :tabId="false" />
    <PageHeader
      :title="this.newAfiTitular ? titleNewTitular : titleNewFamiliar"
    />
    <v-form v-model="isFormValid" ref="newAfiliado-form" id="newAfiliado-form">
      <v-row>
        <v-col cols="12" v-if="showConvenio || newAfiFamiliar || newAfiTitular">
          <v-card>
            <v-card-title>Datos afiliatorios</v-card-title>
            <v-card-text>
              <v-row>
                <!-- Fecha alta -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-menu
                    ref="fechaAlta"
                    v-model="menuFechaAltaAfiliado"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedfechaAltaAfiliadoFormatted"
                        label="Fecha alta (*)"
                        v-mask="'##/##/####'"
                        :append-icon="calendarIcon"
                        onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                        hint="Formato DD/MM/AAAA"
                        @blur="
                          fechaAltaAfiliado = parseDateToIso(
                            computedfechaAltaAfiliadoFormatted
                          )
                        "
                        @keydown.tab="menuFechaAltaAfiliado = false"
                        :rules="
                          computedfechaAltaAfiliadoFormatted
                            ? rules.validDate
                            : []
                        "
                        @input="menuFechaAltaAfiliado = false"
                        @change="
                          fechaAltaAfiliado = parseDateToIso(
                            computedfechaAltaAfiliadoFormatted
                          );
                          updateConvenios();
                        "
                        dense
                        outlined
                        autocomplete="off"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="disabledForEdit"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fechaAltaAfiliado"
                      @change="
                        updateConvenios();
                        computedfechaAltaAfiliadoFormatted = formatDate(
                          fechaAltaAfiliado
                        );
                      "
                      no-title
                      scrollable
                      @input="menuFechaAltaAfiliado = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <!-- Convenios -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-autocomplete
                    v-model="convenioSelected"
                    :items="convenios"
                    label="Convenio (*)"
                    item-text="value"
                    item-value="id"
                    :disabled="
                      disabledForEdit ||
                        (this.newAfiFamiliar &&
                          !this.parametrosGrales.permiteCambiarConvIntegrante)
                    "
                    outlined
                    :rules="
                      this.disabledForEdit === false ? rules.required : []
                    "
                    @change="
                      updateSubconveniosAndPlanes(), updateVtoCredencial()
                    "
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <!-- Subconvenio -->
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                  class="py-0"
                  v-if="
                    this.newAfiTitular ||
                    this.newAfiFamiliar ||
                    this.editAfiliado
                      ? this.parametrosGrales.subConvenio === true
                      : false
                  "
                >
                  <v-autocomplete
                    v-model="subConvenioSelected"
                    :items="subConvenios"
                    label="Subconvenio"
                    item-text="value"
                    item-value="id"
                    outlined
                    clearable
                    dense
                    :disabled="
                      disabledForEdit ||
                        (!esAlta && !newAfiFamiliar && !newAfiTitular)
                    "
                  >
                  </v-autocomplete>
                </v-col>
                <!-- Plan -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-autocomplete
                    v-model="planSelected"
                    :items="planes"
                    label="Plan (*)"
                    :disabled="disabledForEdit"
                    :rules="
                      this.disabledForEdit === false ? rules.required : []
                    "
                    item-text="value"
                    item-value="id"
                    outlined
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <!-- Nro afiliado -->
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                  class="py-0"
                  v-if="
                    this.newAfiTitular || this.newAfiFamiliar
                      ? this.parametrosGrales.paAfiliado === false
                      : true
                  "
                >
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                    label="Número de afiliado (*)"
                    :disabled="disabledForEdit"
                    v-model="numeroAfiliado"
                    autocomplete="off"
                    :rules="
                      this.disabledForEdit === false ? rules.required : []
                    "
                  ></v-text-field>
                </v-col>
                <!-- Número grupo afiliado -->
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                  class="py-0"
                  v-if="
                    this.newAfiTitular || this.newAfiFamiliar
                      ? this.parametrosGrales.paBenGr === false
                      : true
                  "
                >
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    :disabled="this.benGrDisabled === true || disabledForEdit"
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                    label="Número de grupo familiar (*)"
                    v-model="numeroGrp"
                    autocomplete="off"
                    :rules="
                      this.disabledForEdit === false ? rules.required : []
                    "
                  ></v-text-field>
                </v-col>
                <!-- Tipo benef -->
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                  class="py-0"
                  v-if="
                    this.newAfiTitular ||
                    this.newAfiFamiliar ||
                    this.editAfiliado
                      ? this.parametrosGrales.tipoBenDepLegajo === true
                      : false
                  "
                >
                  <v-autocomplete
                    v-model="tipoBenefSelected"
                    :items="tiposBenef"
                    label="Tipo de beneficiario"
                    item-text="value"
                    :disabled="disabledForEdit"
                    item-value="id"
                    outlined
                    clearable
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <!-- Dependencia -->
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                  class="py-0"
                  v-if="
                    this.newAfiTitular ||
                    this.newAfiFamiliar ||
                    this.editAfiliado
                      ? this.parametrosGrales.tipoBenDepLegajo === true
                      : false
                  "
                >
                  <v-autocomplete
                    v-model="dependenciaSelected"
                    :items="dependencias"
                    label="Dependencia"
                    item-text="value"
                    :disabled="disabledForEdit"
                    item-value="id"
                    outlined
                    clearable
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <!-- Vto. de credencial -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    v-mask="'######'"
                    :disabled="
                      newAfiFamiliar || newAfiTitular || !canEditVtoCredencial
                    "
                    label="Vto. de credencial"
                    v-model="vtoCredencial"
                    :rules="
                      canEditVtoCredencial
                        ? rules.required.concat(rules.periodoYyyyMm)
                        : []
                    "
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- DIVISOR -->
        <v-col cols="12">
          <v-card>
            <v-card-title>Datos personales</v-card-title>
            <v-card-text>
              <v-row>
                <!-- Tipo doc -->
                <v-col cols="12" sm="6" md="2" class="py-0">
                  <v-autocomplete
                    v-model="tipoDocSelected"
                    :items="tiposDoc"
                    label="Tipo de documento (*)"
                    :rules="rules.required"
                    item-text="value"
                    item-value="id"
                    outlined
                    dense
                    :disabled="!esAlta && !newAfiFamiliar && !newAfiTitular"
                  >
                  </v-autocomplete>
                </v-col>
                <!-- Número de documento -->
                <v-col
                  cols="12"
                  sm="6"
                  :md="
                    this.parametrosGrales.tipoBenDepLegajo &&
                    this.parametrosGrales.permitirEditarCtaEnAfiliados
                      ? '3'
                      : '2'
                  "
                  class="py-0"
                >
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    label="N° de documento (*)"
                    v-mask="['#.###.###', '##.###.###']"
                    v-model="docAfiliado"
                    autocomplete="off"
                    :rules="
                      rules.required.concat([rules.minLength(docAfiliado, 7)])
                    "
                    :disabled="!esAlta && !newAfiFamiliar && !newAfiTitular"
                  ></v-text-field>
                </v-col>
                <!-- Número de CUIL -->
                <v-col cols="12" sm="6" md="2" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    @change="validarCUIL(cuil)"
                    :label="
                      newAfiTitular && this.parametrosGrales.cuilObligatorio
                        ? 'CUIL (*)'
                        : 'CUIL'
                    "
                    v-mask="['##-#######-#', '##-########-#']"
                    v-model="cuil"
                    :rules="
                      newAfiTitular && this.parametrosGrales.cuilObligatorio
                        ? rules.required.concat([rules.minLength(cuil, 12)])
                        : [rules.minLength(cuil, 12)]
                    "
                    autocomplete="off"
                    :disabled="!esAlta && !newAfiFamiliar && !newAfiTitular"
                  ></v-text-field>
                </v-col>
                <!-- Legajo -->
                <v-col
                  cols="12"
                  :md="
                    this.parametrosGrales.permitirEditarCtaEnAfiliados
                      ? '3'
                      : '2'
                  "
                  sm="6"
                  class="py-0"
                  v-if="
                    this.newAfiTitular ||
                    this.newAfiFamiliar ||
                    this.editAfiliado
                      ? this.parametrosGrales.tipoBenDepLegajo === true
                      : false
                  "
                >
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    label="Legajo"
                    :rules="
                      legajoAfi !== null ? [rules.maxLength(legajoAfi, 50)] : []
                    "
                    v-model="legajoAfi"
                    autocomplete="off"
                    :disabled="!esAlta && !newAfiFamiliar && !newAfiTitular"
                  ></v-text-field>
                </v-col>
                <!-- Numero de cuenta del titular -->
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  class="py-0"
                  v-if="
                    this.newAfiTitular ||
                    this.newAfiFamiliar ||
                    this.editAfiliado
                      ? this.parametrosGrales.permitirEditarCtaEnAfiliados ===
                        true
                      : false
                  "
                >
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    :rules="[rules.maxNumber(nroCuentaTitular, 2147483647)]"
                    label="N° de cuenta"
                    v-mask="'##########'"
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                    v-model="nroCuentaTitular"
                    autocomplete="off"
                    :disabled="!esAlta && !newAfiFamiliar && !newAfiTitular"
                  ></v-text-field>
                </v-col>
                <!-- Apellido/s -->
                <v-col cols="12" sm="6" md="4" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    label="Apellido/s (*)"
                    v-model.trim="apellido"
                    autocomplete="off"
                    :rules="
                      rules.required.concat([
                        rules.requiredTrim(apellido),
                        rules.maxLength(apellido, 45)
                      ])
                    "
                    :disabled="!esAlta && !newAfiFamiliar && !newAfiTitular"
                  ></v-text-field>
                </v-col>
                <!-- Nombre/s -->
                <v-col cols="12" sm="6" md="4" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    label="Nombre/s"
                    v-model="nombre"
                    autocomplete="off"
                    :rules="[rules.maxLength(nombre, 45)]"
                    :disabled="!esAlta && !newAfiFamiliar && !newAfiTitular"
                  ></v-text-field>
                </v-col>
                <!-- Parentesco -->
                <v-col cols="12" sm="6" md="4" class="py-0">
                  <v-autocomplete
                    v-model="parentescoSelected"
                    :items="parentescos"
                    label="Parentesco (*)"
                    item-text="parNom"
                    item-value="parId"
                    outlined
                    :rules="
                      this.disabledForEdit === false ? rules.required : []
                    "
                    :disabled="this.disabledForEdit"
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <!-- Fecha de nacimiento -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-menu
                    ref="fechaNacimiento"
                    v-model="menuFechaNacimientoAfiliado"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedfechaNacimientoFormatted"
                        label="Fecha de nacimiento (*)"
                        onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                        :append-icon="calendarIcon"
                        v-mask="'##/##/####'"
                        hint="Formato DD/MM/AAAA"
                        @blur="
                          fechaNacimientoAfiliado = parseDateToIso(
                            computedfechaNacimientoFormatted
                          )
                        "
                        :rules="
                          (computedfechaNacimientoFormatted
                            ? rules.validDate
                            : []
                          ).concat(rules.required)
                        "
                        @change="
                          fechaNacimientoAfiliado = parseDateToIso(
                            computedfechaNacimientoFormatted
                          );
                          calcularEdad(fechaNacimientoAfiliado);
                        "
                        dense
                        @input="menuFechaNacimientoAfiliado = false"
                        @keydown.tab="menuFechaNacimientoAfiliado = false"
                        outlined
                        autocomplete="off"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!esAlta && !newAfiFamiliar && !newAfiTitular"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fechaNacimientoAfiliado"
                      @change="
                        calcularEdad(fechaNacimientoAfiliado);
                        computedfechaNacimientoFormatted = formatDate(
                          fechaNacimientoAfiliado
                        );
                      "
                      no-title
                      scrollable
                      @input="menuFechaNacimientoAfiliado = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <!-- Edad -->
                <v-col cols="12" sm="6" md="1" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    :disabled="edadAfiliadoLabel"
                    label="Edad"
                    v-model="edadAfiliado"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
                <!-- Sexo -->
                <v-col cols="12" sm="6" md="2" class="py-0">
                  <v-autocomplete
                    v-model="generoSelected"
                    :items="generos"
                    label="Sexo (*)"
                    :rules="rules.required"
                    item-text="value"
                    item-value="value"
                    outlined
                    dense
                    :disabled="!esAlta && !newAfiFamiliar && !newAfiTitular"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="1" md="1" sm="1" class="py-0 px-0">
                  <v-tooltip right max-width="10rem">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="1.4rem"
                        class="pt-2"
                        v-on="on"
                        v-bind="attrs"
                        color="primary"
                      >
                        {{ infoIcon }}</v-icon
                      >
                    </template>
                    <span>Sexo biológico</span>
                  </v-tooltip>
                </v-col>
                <!-- Género -->
                <v-col cols="12" sm="6" md="3" class="py-0 pl-0">
                  <v-autocomplete
                    v-model="generosAutopercibidosSelected"
                    :items="generosAutopercibidos"
                    label="Género"
                    item-text="value"
                    item-value="id"
                    outlined
                    clearable
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="1" md="1" sm="1" class="py-0 px-0">
                  <v-tooltip right max-width="10rem">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="1.4rem"
                        class="pt-2"
                        v-on="on"
                        color="primary"
                        v-bind="attrs"
                      >
                        {{ infoIcon }}</v-icon
                      >
                    </template>
                    <span>Género autopercibido por el afiliado.</span>
                  </v-tooltip>
                </v-col>
                <!-- Nacionalidad -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-autocomplete
                    v-model="nacionalidadSelected"
                    :items="nacionalidades"
                    label="Nacionalidad (*)"
                    :rules="rules.required"
                    item-text="value"
                    item-value="id"
                    outlined
                    dense
                    :disabled="!esAlta && !newAfiFamiliar && !newAfiTitular"
                  >
                  </v-autocomplete>
                </v-col>

                <!-- Email -->
                <v-col cols="12" sm="6" md="4" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    :rules="rules.email"
                    label="Email"
                    v-model="emailAfiliado"
                    autocomplete="off"
                    :disabled="!esAlta && !newAfiFamiliar && !newAfiTitular"
                  ></v-text-field>
                </v-col>
                <!-- Est. Civil -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-autocomplete
                    v-model="estadoCivilSelected"
                    :items="estadosCiviles"
                    label="Estado civil (*)"
                    item-text="value"
                    :rules="rules.required"
                    item-value="id"
                    outlined
                    dense
                    :disabled="!esAlta && !newAfiFamiliar && !newAfiTitular"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- DIVISOR -->
        <v-col cols="12">
          <!-- Domicilios -->
          <v-card>
            <v-tabs background-color="transparent" v-model="tab">
              <v-tab href="#legal" tabindex="-1">Domicilio legal</v-tab>
              <v-tab
                href="#laboral"
                :disabled="addDomicilioClicked === false"
                tabindex="-1"
                >Domicilio laboral</v-tab
              >
              <v-tooltip bottom v-if="addDomicilioClicked === false">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    tabindex="-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="addDomicilioLaboral"
                    :disabled="!esAlta && !newAfiFamiliar && !newAfiTitular"
                    >{{ addDomicilioIcon }}</v-icon
                  >
                </template>
                <span>Agregar domicilio laboral</span>
              </v-tooltip>
              <v-tooltip bottom v-if="addDomicilioClicked === true">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteDomicilioLaboral"
                    :disabled="!esAlta && !newAfiFamiliar && !newAfiTitular"
                    >{{ deleteDomicilioIcon }}</v-icon
                  >
                </template>
                <span>Quitar domicilio laboral</span>
              </v-tooltip>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item value="legal">
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <!-- Calle -->
                      <v-col cols="12" sm="6" md="6" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          :rules="
                            rules.required.concat([
                              rules.requiredTrim(calleLegal),
                              rules.maxLength(calleLegal, 40)
                            ])
                          "
                          dense
                          label="Calle (*)"
                          v-model.trim="calleLegal"
                          autocomplete="off"
                          :disabled="
                            !esAlta && !newAfiFamiliar && !newAfiTitular
                          "
                        ></v-text-field>
                      </v-col>
                      <!-- Número domicilio -->
                      <v-col cols="12" sm="6" md="2" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          v-mask="'######'"
                          onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                          label="Número"
                          v-model="numDomicilioLegal"
                          autocomplete="off"
                          :disabled="
                            !esAlta && !newAfiFamiliar && !newAfiTitular
                          "
                        ></v-text-field>
                      </v-col>
                      <!-- Piso -->
                      <v-col cols="12" sm="6" md="2" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          :rules="
                            pisoLegal !== null
                              ? [rules.maxLength(pisoLegal, 10)]
                              : []
                          "
                          label="Piso"
                          v-model="pisoLegal"
                          autocomplete="off"
                          :disabled="
                            !esAlta && !newAfiFamiliar && !newAfiTitular
                          "
                        ></v-text-field>
                      </v-col>
                      <!-- Dpto. -->
                      <v-col cols="12" sm="6" md="2" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          :rules="
                            departamentoLegal !== null
                              ? [rules.maxLength(departamentoLegal, 4)]
                              : []
                          "
                          label="Dpto."
                          v-model="departamentoLegal"
                          autocomplete="off"
                          :disabled="
                            !esAlta && !newAfiFamiliar && !newAfiTitular
                          "
                        ></v-text-field>
                      </v-col>
                      <!-- Codigo postal -->
                      <v-col cols="12" sm="6" md="2" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          :rules="rules.validCodigoPostal"
                          v-mask="'#####'"
                          label="C. Postal"
                          v-model="cPostalLegal"
                          autocomplete="off"
                          :disabled="
                            !esAlta && !newAfiFamiliar && !newAfiTitular
                          "
                        ></v-text-field>
                      </v-col>
                      <!-- Barrio -->
                      <v-col cols="12" sm="6" md="5" class="py-0">
                        <v-autocomplete
                          v-model="barrioLegalSelected"
                          :items="barrios"
                          label="Barrio (*)"
                          item-text="value"
                          :rules="rules.required"
                          item-value="id"
                          outlined
                          dense
                          :disabled="
                            !esAlta && !newAfiFamiliar && !newAfiTitular
                          "
                        >
                        </v-autocomplete>
                      </v-col>
                      <!-- Localidad -->
                      <v-col cols="12" sm="6" md="5" class="py-0">
                        <v-autocomplete
                          v-model="localidadLegalSelected"
                          :items="localidades"
                          label="Localidad (*)"
                          :rules="rules.required"
                          item-text="value"
                          item-value="id"
                          outlined
                          dense
                          :disabled="
                            !esAlta && !newAfiFamiliar && !newAfiTitular
                          "
                        >
                        </v-autocomplete>
                      </v-col>
                      <!-- Teléfono -->
                      <v-col cols="12" sm="6" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          label="Teléfono"
                          :rules="
                            telefonoLegal !== null
                              ? [
                                  rules.requiredTrim(telefonoLegal),
                                  rules.maxLength(telefonoLegal, 15)
                                ]
                              : []
                          "
                          v-model.trim="telefonoLegal"
                          autocomplete="off"
                          :disabled="
                            !esAlta && !newAfiFamiliar && !newAfiTitular
                          "
                        ></v-text-field>
                      </v-col>
                      <!-- Celular -->
                      <v-col cols="12" sm="6" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                          dense
                          hint="Ej. 3519876543"
                          :rules="[rules.maxLength(celularLegal, 15)]"
                          label="Celular"
                          v-model="celularLegal"
                          autocomplete="off"
                          :disabled="
                            !esAlta && !newAfiFamiliar && !newAfiTitular
                          "
                        ></v-text-field>
                      </v-col>
                      <!-- Observaciones -->
                      <v-col class="pt-0">
                        <v-textarea
                          v-model="observacionesLegal"
                          auto-grow
                          :rules="
                            observacionesLegal !== null
                              ? [rules.maxLength(observacionesLegal, 100)]
                              : []
                          "
                          dense
                          outlined
                          label="Observaciones del domicilio"
                          rows="1"
                          :disabled="
                            !esAlta && !newAfiFamiliar && !newAfiTitular
                          "
                        ></v-textarea>
                      </v-col>
                      <!-- Modificar domicilio familiares -->
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        class="py-0"
                        v-if="esTitular"
                      >
                        <v-switch
                          class="mt-1"
                          v-model="modificarDomicilios"
                          label="Modificar domicilio de todos los integrantes del grupo"
                          item-text="value"
                          item-value="id"
                          dense
                          outlined
                        ></v-switch>
                      </v-col>
                      <!-- Modificar agente -->
                      <v-col
                        cols="12"
                        sm="6"
                        md="12"
                        class="py-0"
                        v-if="esTitular"
                      >
                        <v-switch
                          class="mt-1"
                          v-model="modificarAgente"
                          label="Modificar datos del agente de cuenta (sólo si es el mismo que el titular y se encuentra vigente)"
                          item-text="value"
                          item-value="id"
                          dense
                          outlined
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item value="laboral">
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <!-- Calle -->
                      <v-col cols="12" sm="6" md="6" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          :rules="
                            this.addDomicilioClicked
                              ? [
                                  rules.requiredTrim(calleLaboral),
                                  rules.maxLength(calleLaboral, 40)
                                ]
                              : []
                          "
                          dense
                          label="Calle (*)"
                          v-model.trim="calleLaboral"
                          autocomplete="off"
                          :disabled="
                            !esAlta && !newAfiFamiliar && !newAfiTitular
                          "
                        ></v-text-field>
                      </v-col>
                      <!-- Número domicilio -->
                      <v-col cols="12" sm="6" md="2" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          v-mask="'######'"
                          onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                          label="Número"
                          v-model="numDomicilioLaboral"
                          autocomplete="off"
                          :disabled="
                            !esAlta && !newAfiFamiliar && !newAfiTitular
                          "
                        ></v-text-field>
                      </v-col>
                      <!-- Piso -->
                      <v-col cols="12" sm="6" md="2" class="py-0">
                        <v-text-field
                          type="text"
                          onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                          outlined
                          dense
                          :rules="
                            this.addDomicilioClicked === true &&
                            pisoLaboral !== null
                              ? [rules.maxLength(pisoLaboral, 10)]
                              : []
                          "
                          label="Piso"
                          v-model="pisoLaboral"
                          autocomplete="off"
                          :disabled="
                            !esAlta && !newAfiFamiliar && !newAfiTitular
                          "
                        ></v-text-field>
                      </v-col>
                      <!-- Dpto. -->
                      <v-col cols="12" sm="6" md="2" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          :rules="
                            this.addDomicilioClicked === true &&
                            departamentoLaboral !== null
                              ? [rules.maxLength(departamentoLaboral, 4)]
                              : []
                          "
                          label="Dpto."
                          v-model="departamentoLaboral"
                          autocomplete="off"
                          :disabled="
                            !esAlta && !newAfiFamiliar && !newAfiTitular
                          "
                        ></v-text-field>
                      </v-col>
                      <!-- Codigo postal -->
                      <v-col cols="12" sm="6" md="2" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          v-mask="'#####'"
                          dense
                          onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                          label="C. Postal"
                          v-model="cPostalLaboral"
                          autocomplete="off"
                          :disabled="
                            !esAlta && !newAfiFamiliar && !newAfiTitular
                          "
                        ></v-text-field>
                      </v-col>
                      <!-- Barrio -->
                      <v-col cols="12" sm="6" md="5" class="py-0">
                        <v-autocomplete
                          v-model="barrioLaboralSelected"
                          :items="barrios"
                          label="Barrio"
                          item-text="value"
                          :rules="
                            this.addDomicilioClicked === true
                              ? rules.required
                              : []
                          "
                          item-value="id"
                          outlined
                          dense
                          :disabled="
                            !esAlta && !newAfiFamiliar && !newAfiTitular
                          "
                        >
                        </v-autocomplete>
                      </v-col>
                      <!-- Localidad -->
                      <v-col cols="12" sm="6" md="5" class="py-0">
                        <v-autocomplete
                          v-model="localidadLaboralSelected"
                          :items="localidades"
                          label="Localidad"
                          item-text="value"
                          item-value="id"
                          :rules="
                            this.addDomicilioClicked === true
                              ? rules.required
                              : []
                          "
                          outlined
                          dense
                          :disabled="
                            !esAlta && !newAfiFamiliar && !newAfiTitular
                          "
                        >
                        </v-autocomplete>
                      </v-col>
                      <!-- Teléfono -->
                      <v-col cols="12" sm="6" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          label="Teléfono"
                          onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                          v-model.trim="telefonoLaboral"
                          :rules="
                            this.addDomicilioClicked === true &&
                            telefonoLaboral !== null
                              ? [
                                  rules.requiredTrim(telefonoLaboral),
                                  rules.maxLength(telefonoLaboral, 15)
                                ]
                              : []
                          "
                          autocomplete="off"
                          :disabled="
                            !esAlta && !newAfiFamiliar && !newAfiTitular
                          "
                        ></v-text-field>
                      </v-col>
                      <!-- Celular -->
                      <v-col cols="12" sm="6" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                          dense
                          label="Celular"
                          :rules="
                            this.addDomicilioClicked === true
                              ? [rules.maxLength(celularLaboral, 15)]
                              : []
                          "
                          v-model="celularLaboral"
                          autocomplete="off"
                          :disabled="
                            !esAlta && !newAfiFamiliar && !newAfiTitular
                          "
                        ></v-text-field>
                      </v-col>
                      <!-- Observaciones -->
                      <v-col class="pt-0">
                        <v-textarea
                          v-model="observacionesLaboral"
                          auto-grow
                          :rules="
                            this.addDomicilioClicked === true &&
                            observacionesLaboral !== null
                              ? [rules.maxLength(observacionesLaboral, 100)]
                              : []
                          "
                          dense
                          outlined
                          label="Observaciones del domicilio"
                          rows="1"
                          :disabled="
                            !esAlta && !newAfiFamiliar && !newAfiTitular
                          "
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
        <!-- DIVISOR -->
        <v-col cols="12">
          <v-card>
            <v-card-title>Otros datos</v-card-title>
            <v-card-text>
              <v-row>
                <!-- Vendedor -->
                <v-col cols="12" sm="6" md="5" class="py-0">
                  <v-autocomplete
                    v-model="vendedorSelected"
                    :items="vendedores"
                    label="Vendedor"
                    item-text="value"
                    item-value="id"
                    outlined
                    clearable
                    dense
                    :disabled="!esAlta && !newAfiFamiliar && !newAfiTitular"
                  >
                  </v-autocomplete>
                </v-col>
                <!-- Incapacidad -->
                <v-col cols="12" sm="6" md="2" class="py-0">
                  <v-switch
                    class="mt-1"
                    v-model="incapacidadCheck"
                    label="Incapacidad"
                    item-text="value"
                    item-value="id"
                    dense
                    outlined
                    :disabled="!esAlta && !newAfiFamiliar && !newAfiTitular"
                  ></v-switch>
                </v-col>
                <v-row>
                  <v-col
                    cols="4"
                    class="py-0 ml-4"
                    md="3"
                    v-if="(esTitular || newAfiTitular) && relacionBenefProv"
                  >
                    <v-text-field
                      outlined
                      dense
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57) ||                    // Números
                                  (event.charCode >= 65 && event.charCode <= 90) ||                    
                                  (event.charCode >= 97 && event.charCode <= 122) ||                   
                                   event.charCode === 32 ||                                       
                                   event.charCode === 45 ||                                      
                                  (event.charCode >= 33 && event.charCode <= 47) ||                    
                                  (event.charCode >= 58 && event.charCode <= 64) ||                    
                                  (event.charCode >= 91 && event.charCode <= 96) ||                   
                                  (event.charCode >= 123 && event.charCode <= 126)"
                      clearable
                      :rules="[rules.maxLength(codProveedores, 10)]"
                      label="Código de proveedor"
                      v-model.trim="codProveedores"
                      @change="buscarProveedores"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <!-- Proveedor -->
                  <v-col
                    cols="6"
                    sm="6"
                    md="5"
                    class="py-0"
                    v-if="(esTitular || newAfiTitular) && relacionBenefProv"
                  >
                    <v-autocomplete
                      ref="proveedores-autocomplete"
                      v-model="proveedorSelected"
                      :items="proveedores"
                      :search-input.sync="proveedorAutocompleteInput"
                      item-text="value1"
                      item-value="id"
                      :disabled="
                        codProveedores !== null &&
                          this.codProveedores !== undefined &&
                          this.codProveedores !== ''
                      "
                      :filter="customFilterProveedores"
                      outlined
                      clearable
                      return-object
                      dense
                      :loading="loadingBtn"
                      autocomplete="off"
                      label="Proveedor"
                      persistent-hint
                    >
                      <template slot="item" slot-scope="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.value1
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            >Código: {{ item.value2 }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                      <template v-slot:append>
                        <v-progress-circular
                          indeterminate
                          size="28"
                          v-if="proveedoresLoading"
                          color="primary"
                        ></v-progress-circular>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-row>
              <v-row>
                <!-- Observaciones -->
                <v-col class="pt-0 py-0">
                  <v-textarea
                    v-model="observacionesAfiliado"
                    auto-grow
                    dense
                    outlined
                    :rules="
                      observacionesAfiliado !== null
                        ? [rules.maxLength(observacionesAfiliado, 250)]
                        : []
                    "
                    label="Observaciones del afiliado"
                    rows="1"
                    :disabled="!esAlta && !newAfiFamiliar && !newAfiTitular"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions>
      <v-row>
        <v-col cols="esAlta? '11': '12'" class="py-0 px-5">
          <v-btn
            outlined
            v-if="newAfiFamiliar || editAfiliado"
            @click="goBackTo()"
            class="to-right"
          >
            Volver
          </v-btn>
        </v-col>
        <v-col
          cols="1"
          class="py-0 px-1"
          v-if="esAlta || newAfiFamiliar || newAfiTitular"
        >
          <v-btn
            color="primary"
            :disabled="
              !isFormValid ||
                (codProveedores && !proveedorSelected) ||
                (proveedorSelected && !codProveedores)
            "
            :loading="isLoadingBtn"
            @click="saveEdit()"
            class="to-right"
          >
            Guardar
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-container>
</template>

<script>
import GoBackBtn from "@/components/shared/GoBackBtn";
import moment from "moment";
import getAgeFromDate from "@/utils/helpers/getAgeFromDate";
import validateCUIL from "@/utils/helpers/validateCUIL";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";

export default {
  name: "EditAfiliado",
  directives: { mask },
  components: {
    PageHeader,
    GoBackBtn
  },
  created() {
    this.setSelects();
    this.setParametrosGrales();
    this.tipoRadicSelected = "Legal";
    if (this.$route.params.newAfiTitular) {
      this.newAfiTitular = this.$route.params.newAfiTitular;
    } else if (this.$route.params.benId) {
      this.editAfiliado = true;
      this.benId = this.$route.params.benId;
      this.disabledForEdit = true;
      this.esTitular = this.$route.params.esTitular;
      this.fechaConsulta = this.$route.params.fechaConsulta;
      this.esAlta = this.$route.params.esAlta;
      this.showConvenio = this.$route.params.showConvenio;
      this.titleNewFamiliar = !this.esAlta ? "Ver afiliado" : "Editar afiliado";
      this.banderaProveedorSelected = true;
      this.canEditVtoCredencial = this.$route.params.canEditVtoCredencial;
      this.setAfiliado();
    } else if (this.$route.params.newAfiFamiliar) {
      this.benGrDisabled = true;
      this.numeroGrp = this.$route.params.benGrId;
      this.newAfiFamiliar = true;
      this.setDatosFamiliar();
      this.domicilioFamiliarByTit = this.$route.params.domicilioTitular;
      this.setDomicilioFamiliar();
    }
  },
  data() {
    return {
      routeToGo: "GestionAfiliados",
      benId: null,
      infoIcon: enums.icons.SNB_INFO,
      domicilioFamiliarByTit: null,
      editAfiliado: false,
      fechaConsulta: null,
      titleNewTitular: "Nuevo titular",
      titleNewFamiliar: "Nuevo familiar",
      newAfiFamiliar: false,
      esTitular: false,
      isFormValid: false,
      fechaAltaAfiliado: null,
      menuFechaAltaAfiliado: null,
      disabledForEdit: false,
      computedfechaAltaAfiliadoFormatted: null,
      calendarIcon: enums.icons.CALENDAR,
      rules: rules,
      numeroGrp: null,
      modificarAgente: false,
      modificarDomicilios: false,
      tiposBenef: [],
      tipoBenefSelected: null,
      dependenciaSelected: null,
      dependencias: [],
      convenioSelected: null,
      convenios: [],
      planSelected: null,
      planes: [],
      subConvenioSelected: null,
      subConvenios: [],
      newNroAfi: true,
      codProveedores: null,
      numeroAfiliado: null,
      tiposDoc: [],
      tipoDocSelected: null,
      docAfiliado: null,
      legajoAfi: null,
      apellido: null,
      nombre: null,
      parentescoSelected: null,
      parentescos: [],
      cuil: null,
      fechaNacimientoAfiliado: null,
      menuFechaNacimientoAfiliado: null,
      computedfechaNacimientoFormatted: null,
      edadAfiliado: 0,
      edadAfiliadoLabel: true,
      generoSelected: null,
      generos: [{ value: "M" }, { value: "F" }],
      nacionalidadSelected: null,
      nacionalidades: [],
      estadosCiviles: [],
      estadoCivilSelected: null,
      emailAfiliado: null,
      tab: null,
      tabLaboral: true,
      addDomicilioClicked: false,
      tiposRadic: [],
      tipoRadicLegal: 1,
      tipoRadicLaboral: 2,
      calleLegal: null,
      numDomicilioLegal: null,
      pisoLegal: null,
      departamentoLegal: null,
      cPostalLegal: null,
      barrios: [],
      barrioLegalSelected: null,
      localidadLegalSelected: null,
      localidades: [],
      telefonoLegal: null,
      celularLegal: null,
      observacionesLegal: null,
      calleLaboral: null,
      numDomicilioLaboral: null,
      pisoLaboral: null,
      departamentoLaboral: null,
      cPostalLaboral: null,
      barrioLaboralSelected: null,
      localidadLaboralSelected: null,
      telefonoLaboral: null,
      celularLaboral: null,
      observacionesLaboral: null,
      vendedorSelected: null,
      vendedores: [],
      incapacidadCheck: false,
      observacionesAfiliado: null,
      addDomicilioIcon: enums.icons.CIRCLE_ADD_ITEM,
      deleteDomicilioIcon: enums.icons.CLOSE_WINDOW,
      asistenteOn: true,
      newAfiTitular: false,
      parametrosGrales: {},
      fechaActual: new Date().toISOString().substr(0, 10),
      benGrDisabled: false,
      permiteCambiarConvenio: false,
      esAlta: null,
      showConvenio: true,
      nroCuentaTitular: null,
      planActual: null,
      isLoadingBtn: false,
      proveedorAutocompleteInput: null,
      proveedoresLoading: null,
      proveedores: [],
      proveedorSelected: null,
      banderaProveedorSelected: false,
      relacionBenefProv: false,
      generosAutopercibidos: [],
      provNombre: null,
      loadingBtn: false,
      generosAutopercibidosSelected: null,
      vtoCredencial: null,
      canEditVtoCredencial: false,
      provCod: null
    };
  },
  watch: {
    fechaNacimientoAfiliado() {
      if (this.fechaNacimientoAfiliado > this.fechaActual) {
        this.fechaNacimientoAfiliado = null;
        this.computedfechaNacimientoFormatted = null;
        this.menuFechaNacimientoAfiliado = null;
        this.setAlert({
          type: "warning",
          message:
            "La fecha de nacimiento no puede ser posterior a la fecha actual."
        });
      }
    },
    docAfiliado(val) {
      const valor = val.replaceAll(".", "");
      if (valor.length == 7) this.docMask = "#.###.###";
      if (valor.length == 8) this.docMask = "##.###.###";
    },
    proveedorAutocompleteInput(val) {
      if (val && val.trim().length >= 3) {
        this.getProveedoresFilter();
      }
    }
  },
  methods: {
    ...mapActions({
      fetchTiposProcesosFormatosById:
        "prestadores/fetchTiposProcesosFormatosById",
      getNacionalidades: "afiliaciones/getNacionalidades",
      getProveedoresByCod: "afiliaciones/getProveedoresByCod",
      getTiposBenef: "afiliaciones/getTiposBenef",
      getDependenciasBenef: "afiliaciones/getDependenciasBenef",
      getEstadosCiviles: "afiliaciones/getEstadosCiviles",
      getVendedores: "afiliaciones/getVendedores",
      getPlanesByConvenioAndVigencia:
        "afiliaciones/getPlanesByConvenioAndVigencia",
      getConveniosByVigencia: "afiliaciones/getConveniosByVigencia",
      getTiposDoc: "afiliaciones/getTiposDoc",
      getTiposRadic: "afiliaciones/getTiposRadic",
      getParentescos: "afiliaciones/getParentescos",
      getBarrios: "afiliaciones/getBarrios",
      getLocalidades: "afiliaciones/getLocalidades",
      getParametrosGralesBenef: "afiliaciones/getParametrosGralesBenef",
      setAlert: "user/setAlert",
      postAfiliado: "afiliaciones/postAfiliado",
      postFamiliar: "afiliaciones/postFamiliar",
      getAfiliadoById: "afiliaciones/getAfiliadoById",
      getAfiliadoPreAltaById: "afiliaciones/getAfiliadoPreAltaById",
      getSubconveniosByConvenio: "afiliaciones/getSubconveniosByConvenio",
      postAfiliadoPreAlta: "afiliaciones/postAfiliadoPreAlta",
      getGeneros: "afiliaciones/getGeneros",
      getVtoCredencialAfiliado: "afiliaciones/getVtoCredencialAfiliado",
      fetchProveedoresByQuery: "proveedores/fetchProveedoresByQuery"
    }),
    async buscarProveedores(numberProv) {
      if (
        this.codProveedores !== null &&
        this.codProveedores !== undefined &&
        this.codProveedores !== ""
      ) {
        try {
          this.loadingBtn = true;
          // if(numberProv == null)

          this.findProveedores = await this.getProveedoresByCod(numberProv);
          if (this.findProveedores.length === 0) {
            this.setAlert({
              type: "warning",
              message: "Código de proveedor inexistente"
            });
            this.codProveedores = null;
            this.proveedorSelected = null;
            this.provNombre = 0;
            this.loadingBtn = false;
            return;
          }
          this.loadingBtn = false;
          this.proveedores = this.findProveedores;
          this.proveedorSelected = this.proveedores[0];
        } catch (error) {
          this.loadingBtn = false;
          this.proveedorSelected = null;
        }
      } else {
        this.proveedorSelected = null;
        this.provNombre = 0;
      }
    },

    setDatosFamiliar() {
      this.fechaAltaAfiliado = this.$route.params.fechaConsulta;
      this.computedfechaAltaAfiliadoFormatted = this.formatDate(
        this.fechaAltaAfiliado
      );
      this.updateConvenios();
      this.updateSubconveniosAndPlanes();
    },
    setDomicilioFamiliar() {
      this.calleLegal = this.domicilioFamiliarByTit.calle;
      this.numDomicilioLegal = this.domicilioFamiliarByTit.numero;
      this.pisoLegal = this.domicilioFamiliarByTit.piso;
      this.departamentoLegal = this.domicilioFamiliarByTit.depto;
      this.cPostalLegal = this.domicilioFamiliarByTit.codigoPostal;
      this.barrioLegalSelected = this.domicilioFamiliarByTit.barrioId;
      this.localidadLegalSelected = this.domicilioFamiliarByTit.localidadId;
      this.observacionesLegal = this.domicilioFamiliarByTit.observaciones;
      this.telefonoLegal = this.domicilioFamiliarByTit.telParticular;
      this.celularLegal = this.domicilioFamiliarByTit.telCelular;
    },
    async setParametrosGrales() {
      const response = await this.getParametrosGralesBenef();
      this.parametrosGrales = response;
      this.permiteCambiarConvenio = this.parametrosGrales.permiteCambiarConvIntegrante;
      this.tipoDocSelected = this.parametrosGrales.tdPredet;
      this.relacionBenefProv = this.parametrosGrales.relacionBenefProv;
    },
    functionFormatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    async setSelects() {
      const tiposDoc = await this.getTiposDoc();
      this.tiposDoc = tiposDoc;
      const data = await this.getParentescos();
      if (this.newAfiTitular) {
        this.parentescos = data.filter(x => x.esTitular);
        if (this.parentescos.length === 1)
          this.parentescoSelected = this.parentescos[0].parId;
      } else if (this.disabledForEdit === true) {
        this.parentescos = data;
      } else {
        this.parentescos = data.filter(x => !x.esTitular);
      }
      const nacionalidades = await this.getNacionalidades();
      this.nacionalidades = nacionalidades;
      const vendedores = await this.getVendedores();
      this.vendedores = vendedores;
      const dependencias = await this.getDependenciasBenef();
      this.dependencias = dependencias;
      const tiposBenef = await this.getTiposBenef();
      this.tiposBenef = tiposBenef;
      const estCiviles = await this.getEstadosCiviles();
      this.estadosCiviles = estCiviles;
      const localidades = await this.getLocalidades();
      this.localidades = localidades;
      const barrios = await this.getBarrios();
      this.barrios = barrios;
      const tiposRadic = await this.getTiposRadic();
      this.tiposRadic = tiposRadic;
      this.generosAutopercibidos = await this.getGeneros();
    },
    async setAfiliado() {
      const response = this.showConvenio
        ? await this.getAfiliadoById({
            benId: this.benId,
            fechaConsulta: this.fechaConsulta
          })
        : await this.getAfiliadoPreAltaById(this.benId);
      if (this.showConvenio) {
        // datos de la tabla benefCambio
        this.computedfechaAltaAfiliadoFormatted = this.functionFormatDate(
          response.fechaAlta
        );
        this.fechaAltaAfiliado = response.fechaAlta;
        this.dependenciaSelected = response.dependenciaId;
        this.updateConvenios();
        this.convenioSelected = response.convenioId;
        this.planActual = response.planId;
        this.updateSubconveniosAndPlanes();
        this.subConvenioSelected = response.subConvenio;
        this.planSelected = response.planId;
        this.tipoBenefSelected = response.tipoBenefId;
      }
      this.apellido = response.apellido;
      this.cuil = response.cuil;
      this.vtoCredencial = response.vtoCredencial;
      this.parentescoSelected = response.parId;
      this.docAfiliado = response.dni.toString();
      this.emailAfiliado = response.email;
      this.estadoCivilSelected = response.estCivilId;
      if (
        response.proveedor !== null &&
        (response.proveedor.value2 !== null &&
          response.proveedor.value1 !== null)
      ) {
        this.codProveedores = response.proveedor.value2;
        this.proveedores = [response.proveedor]
        this.proveedorSelected = this.proveedores[0]
      } else {
        this.proveedorAutocompleteInput = null;
        this.codProveedores = null;
      }
      this.fechaNacimientoAfiliado = response.fechaNac;
      this.computedfechaNacimientoFormatted = this.functionFormatDate(
        response.fechaNac
      );
      this.calcularEdad(response.fechaNac);
      this.incapacidadCheck = response.incapacidad;
      this.legajoAfi = response.legajo;
      this.nacionalidadSelected = response.nacId;
      this.nombre = response.nombre;
      this.nroCuentaTitular = response.idAfiliado;
      this.numeroAfiliado = response.nroAfi;
      this.numeroGrp = response.nroGrupo;
      this.observacionesAfiliado = response.observaciones;
      response.domicilios.forEach(x => {
        if (x.tipoRadId === 1) {
          this.barrioLegalSelected = x.barrioId;
          this.calleLegal = x.calle;
          this.cPostalLegal = x.codigoPostal;
          this.departamentoLegal = x.depto;
          this.localidadLegalSelected = x.localidadId;
          this.numDomicilioLegal = x.numero;
          this.observacionesLegal = x.observaciones;
          this.pisoLegal = x.piso;
          this.telefonoLegal = x.telParticular;
          this.celularLegal = x.telCelular;
        }
        if (x.tipoRadId === 2) {
          this.addDomicilioClicked = true;
          this.barrioLaboralSelected = x.barrioId;
          this.calleLaboral = x.calle;
          this.cPostalLaboral = x.codigoPostal;
          this.departamentoLaboral = x.depto;
          this.localidadLaboralSelected = x.localidadId;
          this.numDomicilioLaboral = x.numero;
          this.observacionesLaboral = x.observaciones;
          this.pisoLaboral = x.piso;
          this.telefonoLaboral = x.telParticular;
          this.celularLaboral = x.telCelular;
        }
      });
      this.generoSelected = response.sexo;
      this.generosAutopercibidosSelected = response.generoAutopercibido;
      this.tipoDocSelected = response.tipoDoc;
      this.vendedorSelected = response.venAfiId;
      this.$store.dispatch("user/changeAppLoading", {
        status: false,
        text: "",
        opaque: false
      });
    },
    async setPlanesByConvenio() {
      const res = await this.getPlanesByConvenioAndVigencia({
        convId: this.convenioSelected,
        fechaAlta: this.fechaAltaAfiliado,
        planActual: this.planActual == null ? 0 : this.planActual,
        incluirNoVig: !this.newAfiTitular && !this.newAfiFamiliar
      });
      this.planes = res;
    },
    getProveedoresFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        if (
          this.proveedores?.some(
            x =>
              x.value2 == this.proveedorAutocompleteInput ||
              x.value1 == this.proveedorAutocompleteInput
          )
        ) {
          const proveedorCoincidente = this.proveedores.find(
            x =>
              x.value2 === this.proveedorAutocompleteInput ||
              x.value1 === this.proveedorAutocompleteInput
          );

          if (proveedorCoincidente) {
            this.codProveedores = proveedorCoincidente.value2;
          }
          return;
        } else if (!this.banderaProveedorSelected) {
          this.proveedoresLoading = true;
          const res = await this.fetchProveedoresByQuery({
            input: this.proveedorAutocompleteInput
          });
          this.$refs["proveedores-autocomplete"].cachedItems = [];
          this.proveedores = res;
          this.proveedoresLoading = false;
        } else {
          this.proveedoresLoading = true;
          const response = await this.fetchProveedoresByQuery({
            input: this.proveedorAutocompleteInput
          });
          this.proveedores = response;
          this.proveedorSelected = response[0];
          this.banderaProveedorSelected = false;
          this.proveedoresLoading = false;
        }
      }, 1000);
    },
    customFilterProveedores(item, queryText, itemText) {
      return item.value1 || item.value2.indexOf(queryText) > -1;
    },
    async updateConvenios() {
      this.planes = [];
      this.planSelected = null;
      this.convenios = [];
      this.convenioSelected = null;
      this.subConvenios = [];
      this.subConvenioSelected = null;
      if (
        this.fechaAltaAfiliado !== null &&
        this.fechaAltaAfiliado !== undefined
      ) {
        await this.setConveniosByVigencia();
        if (this.newAfiFamiliar) {
          const obraSocial = this.convenios.find(
            x => x.id == this.$route.params.convenioTitular
          );
          this.convenioSelected =
            obraSocial === undefined ? null : obraSocial.id;
          this.updateVtoCredencial();
          if (this.convenioSelected == null)
            this.setAlert({
              type: "warning",
              message: "El convenio no tiene vigencia a la fecha ingresada."
            });
          this.updateSubconveniosAndPlanes();
        }
      }
    },
    async updateVtoCredencial() {
      if (
        this.convenioSelected !== null &&
        this.convenioSelected !== undefined
      ) {
        const data = {
          fechaAlta: this.fechaAltaAfiliado,
          osId: this.convenioSelected
        };
        const response = await this.getVtoCredencialAfiliado(data);
        this.vtoCredencial = response;
      }
    },
    async updateSubconveniosAndPlanes() {
      this.subConvenios = [];
      this.subConvenioSelected = null;
      this.planes = [];
      this.planSelected = null;
      if (
        this.convenioSelected !== null &&
        this.convenioSelected !== undefined
      ) {
        await this.setSubconvenios();
        await this.setPlanesByConvenio();
        if (this.newAfiFamiliar) {
          this.planSelected = this.$route.params.planTitular;
          this.subConvenioSelected = this.$route.params.subConvenioTitular;
        }
      }
    },
    async setConveniosByVigencia() {
      const res = await this.getConveniosByVigencia({
        fechaAlta: this.fechaAltaAfiliado
      });
      this.convenios = res;
    },
    async setSubconvenios() {
      const response = await this.getSubconveniosByConvenio(
        this.convenioSelected
      );
      this.subConvenios = response;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    calcularEdad(fechaNacimientoAfiliado) {
      this.edadAfiliado = getAgeFromDate.getAge(fechaNacimientoAfiliado);
    },
    validarCUIL(cuil) {
      if (cuil == null || cuil == "") return [];
      const res = validateCUIL.validateCUITCUIL(cuil);
      if (res === false) {
        return ["El CUIL ingresado es incorrecto."];
      }
    },
    async saveEdit() {
      this.isLoadingBtn = true;
      const data = {
        benId: this.$route.params.benId ? this.benId : 0,
        benGrId: this.numeroGrp,
        fechaAlta: this.fechaAltaAfiliado,
        nroAfi: this.numeroAfiliado,
        subConvenio: this.subConvenioSelected,
        convenioId: this.convenioSelected,
        legajo: this.legajoAfi,
        planId: this.planSelected,
        tipoDoc: this.tipoDocSelected,
        nombre: this.nombre != null ? this.nombre.toUpperCase() : null,
        apellido: this.apellido.toUpperCase(),
        parId: this.parentescoSelected,
        idAfiliado: this.nroCuentaTitular,
        fechaNac: this.fechaNacimientoAfiliado,
        sexo: this.generoSelected,
        genero: this.generosAutopercibidosSelected,
        nacId: this.nacionalidadSelected,
        estCivilId: this.estadoCivilSelected,
        dNI: this.docAfiliado.replaceAll(".", ""),
        cUIL: this.cuil ? this.cuil.replaceAll("-", "") : this.cuil,
        observaciones: this.observacionesAfiliado,
        domicilios:
          this.addDomicilioClicked === true
            ? [
                {
                  tipoRadId: this.tipoRadicLegal,
                  localidadId: this.localidadLegalSelected,
                  barrioId: this.barrioLegalSelected,
                  calle:
                    this.calleLegal != null
                      ? this.calleLegal.toUpperCase()
                      : "S/D",
                  numero:
                    this.numDomicilioLegal != null ? this.numDomicilioLegal : 0,
                  piso: this.pisoLegal,
                  depto: this.departamentoLegal,
                  codigoPostal:
                    this.cPostalLegal != null ? this.cPostalLegal : 0,
                  telParticular:
                    this.telefonoLegal != null ? this.telefonoLegal : "    ",
                  telCelular: this.celularLegal,
                  observaciones: this.observacionesLegal
                },
                {
                  tipoRadId: this.tipoRadicLaboral,
                  localidadId: this.localidadLaboralSelected,
                  barrioId: this.barrioLaboralSelected,
                  calle:
                    this.calleLaboral != null
                      ? this.calleLaboral.toUpperCase()
                      : "S/D",
                  numero:
                    this.numDomicilioLaboral != null
                      ? this.numDomicilioLaboral
                      : 0,
                  piso: this.pisoLaboral,
                  depto: this.departamentoLaboral,
                  codigoPostal:
                    this.cPostalLaboral != null ? this.cPostalLaboral : 0,
                  telParticular:
                    this.telefonoLaboral != null ? this.telParticular : "    ",
                  telCelular: this.celularLaboral,
                  observaciones: this.observacionesLaboral
                }
              ]
            : [
                {
                  tipoRadId: this.tipoRadicLegal,
                  localidadId: this.localidadLegalSelected,
                  barrioId: this.barrioLegalSelected,
                  calle:
                    this.calleLegal != null
                      ? this.calleLegal.toUpperCase()
                      : "S/D",
                  numero:
                    this.numDomicilioLegal != null ? this.numDomicilioLegal : 0,
                  piso: this.pisoLegal,
                  depto: this.departamentoLegal,
                  codigoPostal:
                    this.cPostalLegal != null ? this.cPostalLegal : 0,
                  telParticular:
                    this.telefonoLegal != null ? this.telefonoLegal : "    ",
                  telCelular: this.celularLegal,
                  observaciones: this.observacionesLegal
                }
              ],
        tipoBenefId: this.tipoBenefSelected,
        dependenciaId: this.dependenciaSelected,
        email: this.emailAfiliado,
        incapacidad: this.incapacidadCheck,
        venAfiId: this.vendedorSelected,
        modificarAgente: this.modificarAgente,
        modificarDomicilios: this.modificarDomicilios,
        provId: this.proveedorSelected?.id,
        vtoCredencial: this.vtoCredencial
      };
      // TRATAMIENTO PARA GUARDAR AFILIADO EN PREALTA, POR EL MOMENTO SIN USO
      // if (!this.esAlta) {
      //   delete data.subConvenio;
      //   delete data.benGrId;
      //   delete data.fechaAlta;
      //   delete data.nroAfi;
      //   delete data.subConvenio;
      //   delete data.convenioId;
      //   delete data.planId;
      //   delete data.tipoBenefId;
      //   delete data.dependenciaId;
      //   const res = await this.postAfiliadoPreAlta(data);
      //   if (res.status == 200) {
      //     setTimeout(() => {
      //       this.setAlert({ type: "success", message: "Guardado con éxito." });
      //     }, 500);
      //     this.$router.push({
      //       name: "GestionAfiliados",
      //       params: {
      //         fromEdit: true
      //       }
      //     });
      //   }
      //   return
      // }
      try {
        if (this.newAfiTitular) {
          const res = await this.postAfiliado(data);
          if (res) {
            if (res.mensaje)
              this.setAlert({ type: "warning", message: res.mensaje });
            setTimeout(() => {
              this.setAlert({
                type: "success",
                message: "Guardado con éxito."
              });
            }, 2000);
            this.$router.push({
              name: "DatosComplementariosAfiliado",
              params: {
                asistenteOn: this.asistenteOn,
                benId: res.benId,
                benGrId: res.benGrId,
                afiliadoFamiliar: this.newAfiFamiliar,
                newAfiliado: data,
                fechaConsulta: this.fechaAltaAfiliado
              }
            });
            this.$store.dispatch("user/changeAppLoading", {
              status: true,
              text: "",
              opaque: true
            });
          }
        }
        if (this.editAfiliado) {
          const tiposDoc = await this.postAfiliado(data);
          if (tiposDoc) {
            if (tiposDoc.mensaje) {
              this.setAlert({ type: "warning", message: tiposDoc.mensaje });
            }
            setTimeout(() => {
              this.setAlert({
                type: "success",
                message: "Guardado con éxito."
              });
            }, 1000);
            if (this.$route.params.benId) {
              this.$router.push({
                name: "GestionAfiliados",
                params: {
                  fromEdit: true
                }
              });
            }
            this.$store.dispatch("user/changeAppLoading", {
              status: true,
              text: "",
              opaque: true
            });
          }
        }
        if (this.newAfiFamiliar) {
          const response = await this.postFamiliar(data);
          if (response) {
            if (response.mensaje)
              this.setAlert({ type: "warning", message: response.mensaje });
            setTimeout(() => {
              this.setAlert({
                type: "success",
                message: "Guardado con éxito."
              });
            }, 4000);
            const parentescoConyuge = this.parentescos.find(
              x => x.parId === this.parentescoSelected
            );
            this.$router.push({
              name: "DatosComplementariosAfiliado",
              params: {
                afiliadoFamiliar: this.newAfiFamiliar,
                newAfiliado: data,
                asistenteOn:
                  parentescoConyuge.esConyuge === true ? true : false,
                benId: response.benId,
                benGrId: this.numeroGrp,
                fechaConsulta: this.$route.params.fechaConsulta,
                canSeeGestionMovimientos: true
              }
            });
            this.$store.dispatch("user/changeAppLoading", {
              status: true,
              text: "",
              opaque: true
            });
          }
        }
        this.isLoadingBtn = false;
      } catch {
        this.isLoadingBtn = false;
      }
    },
    goBackTo() {
      if (this.newAfiFamiliar) {
        this.$router.push({
          name: "DatosComplementariosAfiliado",
          params: {
            asistenteOn: false,
            benGrId: this.numeroGrp,
            benId: this.$route.params.afiliadoId,
            fechaConsulta: this.$route.params.fechaConsulta,
            canSeeGestionMovimientos: true
          }
        });
        this.$store.dispatch("user/changeAppLoading", {
          status: true,
          text: "",
          opaque: true
        });
      } else {
        this.$router.push({
          name: "GestionAfiliados",
          params: {
            fromEdit: true
          }
        });
      }
    },
    addDomicilioLaboral() {
      this.tab = "laboral";
      this.addDomicilioClicked = true;
    },
    deleteDomicilioLaboral() {
      this.tab = "legal";
      this.addDomicilioClicked = false;
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>
